import {
  eolsBaseApi,
  withApiErrorLoggingOnly
} from '../../utilities/api.utilities';
import { addSearchParams } from '../../utilities/app.utilities';
import {
  CourseManagementSyllabusItemsDto,
  EbookDto,
  SyllabusAssignmentDto,
  SyllabusFetchSearchParams,
  UserDto,
  VitalSourceDto,
  CatalogWithExternalEntitiesDto,
  AccessTokenRequestDto,
  NewTokenDto,
  CourseCopyPreviewDto,
  OsmosisTokenDto,
  SherpathClassicModuleDto,
} from './sherpath-course-management-service.dtos';
import {
  SyllabusFetchSearchConfig,
  VitalSourceFetchConfig,
} from './sherpath-course-management-service.models';
import { SyllabusItemDto } from '../sherpath-syllabus-service/sherpath-syllabus-service.dtos';
import { AssessmentSubmissionDto } from '../eols-assessment-service/eols-assessment-service.dtos';
import {
  RecContentItemTypeDto
} from '../rec-gateway/rec-gateway.dtos';
import { SkillPerformanceDto } from '../ocs-api-service/ocs-api-service.dtos';
import { SyllabusItemTypeDto } from '../sherpath-syllabus-service/sherpath-syllabus-service.constants';
import { NewUserRole } from '../../pages/settings/AddNewUserModal.component';
import { getEvolveToken } from '../evolve-user-management-client/evolve-user-management-client.utilities';
import { AssignmentsWithUpdatableGradepoints } from '../../pages/bulk-edit-settings/bulk-edit-settings.constants';
import { ELSCourseMigrationSectionDto } from '../../models/els.dtos';

export const getSyllabusFetchSearchParams = (config: SyllabusFetchSearchConfig): SyllabusFetchSearchParams => {
  return {
    ...config,
    externalIds: config.externalIds.map((externalId) => {
      return `${externalId.type}:${externalId.value}`;
    }).join(',')
  };
};

export const fetchSyllabusItems = (config: SyllabusFetchSearchConfig): Promise<CourseManagementSyllabusItemsDto> => {
  const searchParams = getSyllabusFetchSearchParams(config);
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get<CourseManagementSyllabusItemsDto>(addSearchParams('/sherpath/course-management/syllabusItems', { ...searchParams }));
  });
};

export const fetchSyllabusItemsV3 = (config: {
  isDeleted?: boolean;
  syllabusItemType?: SyllabusItemTypeDto;
  parentId?: string;
  courseSectionIds: string[];
  studentId?: string;
}): Promise<CourseManagementSyllabusItemsDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get<CourseManagementSyllabusItemsDto>(addSearchParams('/sherpath/course-management/syllabusItems/v3', { ...config }));
  });
};

export const putMigrateSyllabusItems = (courseSectionId: string): Promise<CourseManagementSyllabusItemsDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.put<CourseManagementSyllabusItemsDto>(addSearchParams('/sherpath/course-management/syllabusItems/migrate', { courseSectionId }));
  });
};

export const fetchSyllabusItem = (id: string): Promise<SyllabusItemDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get<SyllabusItemDto>(`/sherpath/course-management/syllabusItems/${id}`);
  });
};

export const postSyllabusItem = (syllabusItem: SyllabusItemDto): Promise<SyllabusItemDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post<SyllabusItemDto>('/sherpath/course-management/syllabusItems', {
      data: syllabusItem
    });
  });
};

export const putSyllabusItems = (syllabusItems: SyllabusItemDto[]): Promise<SyllabusItemDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.put<SyllabusItemDto[]>('/sherpath/course-management/syllabusItems/batch', {
      data: syllabusItems
    });
  });
};

export const postSyllabusItems = (syllabusItems: SyllabusItemDto[]): Promise<SyllabusItemDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post<SyllabusItemDto[]>('/sherpath/course-management/syllabusItems/batch', {
      data: syllabusItems
    });
  });
};

export const putSyllabusItem = (syllabusItem: SyllabusItemDto): Promise<SyllabusItemDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.put<SyllabusItemDto>(`/sherpath/course-management/syllabusItems/${syllabusItem.id}`, {
      data: syllabusItem
    });
  });
};

export const fetchEbooks = (): Promise<EbookDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get<EbookDto[]>('/sherpath/course-management/ebooks');
  });
};

export const fetchVitalSource = (config: VitalSourceFetchConfig): Promise<VitalSourceDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post<VitalSourceDto>(`/sherpath/course-management/vst/isbn/${config.isbn}/page/${config.page}`);
  });
};

export const fetchUsers = (courseSectionIds: string[]): Promise<UserDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/sherpath/course-management/users', { courseSectionIds }));
  });
};

export const postSyllabusAssignment = (syllabusAssignmentDto: SyllabusAssignmentDto): Promise<SyllabusAssignmentDto> => {
  // to prevent rejection from the backend due to possible property mismatch with DB,
  // remove isGradepointIndependentGoals from the request body and put it in the query string
  const updatedSyllabusAssignmentDto = syllabusAssignmentDto;
  let isGradepointIndependentGoalsParameter = '';
  // for these assignment types, we always want to have editable grade points
  if (AssignmentsWithUpdatableGradepoints.includes(updatedSyllabusAssignmentDto.assignment.assignmentType)) {
    isGradepointIndependentGoalsParameter = '?isGradepointIndependentGoals=true';
    delete updatedSyllabusAssignmentDto.assignment.isGradepointIndependentGoals;
  }

  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post<SyllabusAssignmentDto>(`/sherpath/course-management/assignment${isGradepointIndependentGoalsParameter}`, {
      data: updatedSyllabusAssignmentDto
    });
  });
};

export const putSyllabusAssignment = (syllabusAssignmentDto: SyllabusAssignmentDto): Promise<SyllabusAssignmentDto> => {
  // to prevent rejection from the backend due to possible property mismatch with DB,
  // remove isGradepointIndependentGoals from the request body and put it in the query string
  const updatedSyllabusAssignmentDto = syllabusAssignmentDto;
  let isGradepointIndependentGoalsParameter = '';
  // for these assignment types, we always want to have editable grade points
  if (AssignmentsWithUpdatableGradepoints.includes(updatedSyllabusAssignmentDto.assignment.assignmentType)) {
    isGradepointIndependentGoalsParameter = '?isGradepointIndependentGoals=true';
    delete updatedSyllabusAssignmentDto.assignment.isGradepointIndependentGoals;
  }

  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.put<SyllabusAssignmentDto>(`/sherpath/course-management/assignment${isGradepointIndependentGoalsParameter}`, {
      data: updatedSyllabusAssignmentDto
    });
  });
};

export const fetchAssessmentSubmissions = (assignmentId: string): Promise<AssessmentSubmissionDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/sherpath/course-management/assessment/submission', { assignmentId }));
  });
};

export const fetchCatalog = (
  isbns: string[],
  learningTypes: RecContentItemTypeDto[],
  inactiveMappingTypes: RecContentItemTypeDto[] = []
): Promise<CatalogWithExternalEntitiesDto> => {
  return withApiErrorLoggingOnly(() => {
    const url = addSearchParams('/sherpath/course-management/recommendation/learning/catalog/v2', {
      'filter[isbns]': isbns,
      included: 'TAXONOMY',
      'filter[learningType]': learningTypes,
      inactiveMappingTypes
    });

    return eolsBaseApi.get(url);
  });
};

export const postCourseCopy = (courseSectionId: string, startDate: string, timezone: string): Promise<string> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post<string>(`/sherpath/course-management/courseSections/${courseSectionId}/copy`, {
      data: { startDate, timezone },
    });
  });
};

export const fetchNewTokenForCourseSwitcher = (body: AccessTokenRequestDto): Promise<NewTokenDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post('/sherpath/course-management/courseSections/switch', {
      data: body
    });
  });
};

export const fetchSkillPerformance = (assignmentId: number): Promise<SkillPerformanceDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/sherpath/course-management/skill/performance/${assignmentId}`);
  });
};

export const fetchCourseCopyPreview = (courseSectionId: string, timezone: string, startDate: string): Promise<CourseCopyPreviewDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams(`/sherpath/course-management/courseSections/${courseSectionId}/copy/preview`, {
      timezone,
      startDate,
    }));
  });
};

export const fetchOsmosisToken = (): Promise<OsmosisTokenDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get('/sherpath/course-management/osmosis/token');
  });
};

export type MigratedCourseEntitlementDto = {
  id: number;
  courseSectionId: number;
  entitlementId: number;
  previousAppId: string;
  updatedAppId: string;
  createdAt: string;
  updatedAt: string;
  createdBy: number;
  updatedBy: number;
}

export const fetchMigratedEntitlements = (courseSectionId: string): Promise<MigratedCourseEntitlementDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/sherpath/course-management/courseSections/${courseSectionId}/migration/entitlement`);
  });
};

export const fetchMissingTaxonomyMappingResources = (courseSectionId: string): Promise<SherpathClassicModuleDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/sherpath/course-management/courseSections/${courseSectionId}/missing/taxonomy/mapping/resources`);
  });
};

export const addUserToCourse = (
  courseExternalId: string,
  instructorId: string,
  newUserEmailAddress: string,
  newUserFirstName: string,
  newUserLastName: string,
  newUserRole: NewUserRole,
): Promise<{}> => {
  return withApiErrorLoggingOnly(() => {
    return getEvolveToken().then((tokenDto) => {
      return eolsBaseApi.post<{}>(`/sherpath/course-management/evolve/enroll/user?sessionId=${tokenDto.sessionId}&instantResponse=true&sendUserEmail=true`, {
        data: {
          resourceId: courseExternalId,
          users: [{
            instructorId,
            email: newUserEmailAddress,
            firstName: newUserFirstName,
            lastName: newUserLastName,
            roles: [newUserRole]
          }]
        }
      });
    });
  });
};

export const removeUserFromEvolveRoster = (
  courseExternalId: string,
  userName: string
): Promise<{}> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post<{}>('/sherpath/course-management/evolve/unEnroll/user', {
      data: {
        courseId: courseExternalId,
        userName
      }
    });
  });
};

export const removeUserFromEOLS = (
  courseId: number,
  userId: number
): Promise<{}> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.delete<{}>(`/sherpath/course-management/courseSections/${courseId}/remove/user/${userId}`);
  });
};

export const removeUserFromShadowHealth = (userId: number): Promise<{}> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post<{}>('/sherpath/course-management/shadowhealth/user/unenrollment', {
      data: {
        userId
      }
    });
  });
};

export const updateEvolveCourseTitle = (
  userName: string,
  courseId: string,
  courseTitle: string
): Promise<{}> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.put<{}>('/sherpath/course-management/evolve/course/title', {
      data: {
        userName,
        courseId,
        courseTitle
      }
    });
  });
};

export const getCourseSectionMigrationStatus = (courseSectionIds: number[]): Promise<ELSCourseMigrationSectionDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post('/sherpath/course-management/courseSections/migration/entitlements', {
      data: courseSectionIds
    });
  });

};
