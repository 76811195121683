import {
  eolsBaseApi,
  withApiErrorLoggingOnly
} from '../../utilities/api.utilities';

export const fetchChatEeoIsbns = (): Promise<string[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get('/florence/chat/eeoisbns');
  });
};
