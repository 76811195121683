import { v4 as uuidv4 } from 'uuid';
import {
  includes,
  isInteger,
  orderBy,
  times,
  uniq,
  uniqBy,
  isEmpty
} from 'lodash';
import moment from 'moment';
import React from 'react';
import { ELSLoggingService } from '@els/els-ui-common-react';
import {
  RecContentItemDto,
  RecContentItemTypeDto,
  RecTaxonomyNodeDto
} from '../../apis/rec-gateway/rec-gateway.dtos';
import {
  getEbookNodeTitle,
  getEvolveResource,
  getTaxonomiesWithFlattenedBranchChildren,
  getUniqueSortedPrimaryTaxonomies,
  isEvolveResourceSupported,
  isTypeInSelectedTypes,
  getTypeFilterOptionsForCatalog,
  getFilterTypeFromContentItem,
  getCatalogItemConfigMapClone,
  getSherpathLessonSequenceMap,
  sortCatalogItemsClone,
  getSherpathLessonSequenceMapClone,
  getTitleClone,
  getSubtitleClone,
  getSyllabusItemTypeFromCatalogItemClone,
} from '../catalog/catalog.utilities';
import {
  DATE_PRIMARY,
  DATE_PRIMARY_CHUNK
} from '../../constants/date.constants';
import {
  CourseBuilderConfig,
  CourseWarningContentItem,
  SyllabusTreeMapItem,
} from './courseBuilder.models';
import { SyllabusItemDto } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.dtos';
import {
  ActiveSyllabusItemTypeDto,
  ExternalIdTypeDto,
  SyllabusItemTypeDto
} from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.constants';
import {
  getAncestorLineFolderIds,
  sortDefault
} from '../course-plan/syllabus.utilities';
import {
  contentCreateMethod,
  COURSE_NAME_MAX_LENGTH,
  SherpathContentType,
  SherpathContentTypeMap
} from './courseBuilder.constants';
import {
  isValidDate,
  truncateSyllabusItemTitles
} from '../../utilities/app.utilities';
import { PrimaryTaxonomy } from '../../apis/rec-gateway/rec-gateway.models';
import { ELSCourseMigrationSectionDto, ELSCourseSectionDto, migrationStatus } from '../../models/els.dtos';
import { CourseSectionDto } from '../../apis/eols-course-crud/eols-course-crud.dtos';
import {
  CatalogEvolveResourcePermission,
  CatalogExternalEntityDto,
  CatalogWithExternalEntitiesDto,
  CourseCopyPreviewDto,
  SherpathClassicContentItemDto,
  SherpathContentTypeDto
} from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import {
  arrayToMapByCustomId,
  joinListCommaSeparated,
  mapToIds,
  stitchArrays
} from '../../utilities/common.utilities';
import { CatalogItemConfigMap } from '../catalog/catalog.models';
import { AssignmentDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { FeatureFlagsGroupedDto } from '../../apis/eols-features-api/eols-features-api.dtos';
import { FEATURE_FLAG, } from '../../apis/eols-features-api/eols-features-api.constants';
import { getFeatureFlagGroups } from '../../utilities/featureFlag.utilities';
import { DefaultCoursePlanEvolveResourceTypeDEPRECATED } from '../../constants/content-type.constants';
import {
  ItemSequenceMap,
} from '../../apis/ocs-api-service/ocs-api-service.dtos';
import {
  EvolveProductDto,
  EvolveProductTypeKey
} from '../../apis/sherpath-app-facade-service/sherpath-app-facade-service.dtos';
import { ResourceFilterMap } from '../../components/resource-filter/ResourceFilter.component';

const fileName = 'courseBuilder.utilities';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getSectionDurationDays = (courseBuilderConfig: CourseBuilderConfig): number => {
  return 7;
  // TODO: Clean me up if this feature passes testing and solidified into product
  // const numberOfDays = moment(courseBuilderConfig.endDate).diff(moment(courseBuilderConfig.startDate), 'days');
  // return Math.ceil(numberOfDays / courseBuilderConfig.numberOfSections);
};

export const getSectionTitle = (
  courseBuilderConfig: CourseBuilderConfig,
  sectionIndex: number
): string => {
  const baseTitle = `${courseBuilderConfig.sectionTitle} ${sectionIndex + 1}`;

  if (!courseBuilderConfig.isIncludeDurationInTitle) {
    return baseTitle;
  }

  const sectionDurationDays = getSectionDurationDays(courseBuilderConfig);

  const sectionStart = moment(courseBuilderConfig.startDate)
    .add(sectionIndex * sectionDurationDays, 'days');

  const sectionEnd = moment(sectionStart)
    .add(sectionDurationDays - 1, 'days');

  return `${baseTitle}: ${sectionStart.format(DATE_PRIMARY_CHUNK)} - ${sectionEnd.format(DATE_PRIMARY_CHUNK)}`;
};

export const buildSections = (courseBuilderConfig: CourseBuilderConfig): SyllabusItemDto[] => {
  const sectionHeaders = [];

  times(courseBuilderConfig.numberOfSections, (number) => {
    const newSyllabusItem: SyllabusItemDto = {
      id: uuidv4(),
      parentId: null,
      title: getSectionTitle(courseBuilderConfig, number),
      subtitle: null,
      type: ActiveSyllabusItemTypeDto.FOLDER,
      displayOrder: number,
      externalIdentifiers: [],
      courseSectionIds: [parseInt(courseBuilderConfig.courseSectionId, 10)]
    };
    sectionHeaders.push(newSyllabusItem);
  });

  return sectionHeaders;
};

// This method evenly distributes extra subsections throughout a course
export const getSectionIdxFromEvenlyDistributedSubSectionIdx = (
  numberOfSections: number,
  numberOfSubsections: number,
  subSectionIdx: number
): number => {

  if (numberOfSections <= 0) {
    return -1;
  }

  if (subSectionIdx >= numberOfSubsections) {
    return -1;
  }

  if (numberOfSections > numberOfSubsections) {
    return subSectionIdx;
  }

  const approxSectionLocation = (subSectionIdx / numberOfSubsections) * numberOfSections;

  return Math.floor(approxSectionLocation);
};

export const buildContentItem = (
  courseBuilderConfig: CourseBuilderConfig,
  contentItem: RecContentItemDto,
  evolveProducts: EvolveProductDto[],
  parentId: string,
  displayOrder: number,
  externalEntitiesMap: Map<string, CatalogExternalEntityDto>
): SyllabusItemDto => {
  // Allowing the title and subtitle to run over 255 char limit here for the preview
  // Will truncate them down before saving to database
  return {
    id: uuidv4(),
    parentId,
    title: getTitleClone(contentItem, externalEntitiesMap, courseBuilderConfig.primaryTaxonomies, null),
    subtitle: getSubtitleClone(contentItem, externalEntitiesMap, courseBuilderConfig.primaryTaxonomies, evolveProducts, null),
    type: getSyllabusItemTypeFromCatalogItemClone(contentItem, externalEntitiesMap),
    displayOrder,
    externalIdentifiers: [{
      type: ExternalIdTypeDto.CATALOG_ITEM_ID,
      value: contentItem.id
    }],
    courseSectionIds: [parseInt(courseBuilderConfig.courseSectionId, 10)],
  };
};

export const isResourceIncludedInDefaultCoursePlan = (
  contentItem: RecContentItemDto,
  catalog: CatalogWithExternalEntitiesDto,
  unSupportedEvolveResourceTypes: string[],
  courseBuilderEvolveResourceTypes: string[],
): boolean => {

  if (contentItem.type !== RecContentItemTypeDto.EVOLVE_RESOURCE) {
    return true;
  }

  const evolveResource = getEvolveResource(contentItem, catalog);

  if (!evolveResource || !evolveResource._parsedData || !evolveResource._parsedData.permission) {
    return true;
  }

  if (!isEvolveResourceSupported(evolveResource, unSupportedEvolveResourceTypes)) {
    return false;
  }

  // Fall back to the old way so as not to break production courses that do not yet have new assetType props
  if (!evolveResource._parsedData.assetType && evolveResource._parsedData.type) {
    if (evolveResource._parsedData.permission.trim().toLowerCase() === CatalogEvolveResourcePermission.STUDENT) {
      return false;
    }
    const normalizedType = evolveResource._parsedData.type.trim().toLowerCase();
    const permittedEvolveResourceTypesDEPRECATED = [
      DefaultCoursePlanEvolveResourceTypeDEPRECATED.CASE_STUDIES.toLowerCase(),
      DefaultCoursePlanEvolveResourceTypeDEPRECATED.CASE_STUDY.toLowerCase(),
      DefaultCoursePlanEvolveResourceTypeDEPRECATED.TEACH.toLowerCase(),
      DefaultCoursePlanEvolveResourceTypeDEPRECATED.PPT.toLowerCase(),
    ];

    return permittedEvolveResourceTypesDEPRECATED.includes(normalizedType);
  }

  const normalizedType = evolveResource._parsedData.assetType.trim();
  return courseBuilderEvolveResourceTypes.includes(normalizedType);
};

export const filterIsTypeIncluded = (courseBuilderConfig: CourseBuilderConfig) => (contentItem: RecContentItemDto): boolean => {

  if (!isResourceIncludedInDefaultCoursePlan(
    contentItem,
    courseBuilderConfig.catalog,
    courseBuilderConfig.unSupportedEvolveResourceTypes,
    courseBuilderConfig.courseBuilderEvolveResourceTypes
  )) {
    return false;
  }

  const typeConfig = getFilterTypeFromContentItem(contentItem, courseBuilderConfig.catalog);

  if (!typeConfig) {
    return false;
  }

  return isTypeInSelectedTypes(courseBuilderConfig.selectedTypes, typeConfig.type) || isTypeInSelectedTypes(courseBuilderConfig.selectedTypes, typeConfig.parentType);
};

export const buildSubsectionContentItems = (
  courseBuilderConfig: CourseBuilderConfig,
  subSectionSyllabusItem: SyllabusItemDto,
  subSectionTaxonomyNode: RecTaxonomyNodeDto,
  catalogItemConfigMap: CatalogItemConfigMap,
  evolveProducts: EvolveProductDto[],
  taxonomyRootNodesMap: Map<string, RecTaxonomyNodeDto>,
  catalogDataMapByNodeId: Record<string, RecContentItemDto[]>,
  originalAndOverrideSequenceMap: { originalSequence: ItemSequenceMap; overrideSequenceMap: Record<string, ItemSequenceMap> },
  externalEntitiesMap: Map<string, CatalogExternalEntityDto>
): SyllabusItemDto[] => {

  const catalogData = catalogDataMapByNodeId[subSectionTaxonomyNode.id];

  const itemSequenceMap = {
    ...originalAndOverrideSequenceMap.originalSequence,
    ...originalAndOverrideSequenceMap.overrideSequenceMap[subSectionTaxonomyNode.id]
  };

  const sortedItems = sortCatalogItemsClone({
    items: catalogData,
    sortedTaxonomyIds: getUniqueSortedPrimaryTaxonomies(courseBuilderConfig.primaryTaxonomies).map(x => x.taxonomy.data[0].id),
    catalog: courseBuilderConfig.catalog,
    catalogItemConfigMap,
    dominantTaxon: subSectionTaxonomyNode,
    taxonomyRootNodesMap,
    externalEntitiesMap,
    itemSequenceMap
  }).sectionedItems;

  return sortedItems.map((contentItem, idx) => {
    return buildContentItem(courseBuilderConfig, contentItem, evolveProducts, subSectionSyllabusItem.id, idx, externalEntitiesMap);
  });
};

export const getSubSectionTaxonomyNodes = (courseBuilderConfig: {
  taxonomyIds: CourseBuilderConfig['taxonomyIds'];
  catalog: CourseBuilderConfig['catalog'];
}): RecTaxonomyNodeDto[] => {

  if (!courseBuilderConfig.taxonomyIds || !courseBuilderConfig.taxonomyIds.length) {
    return [];
  }

  const arrayOfTaxonomyNodeArray = courseBuilderConfig.taxonomyIds.reduce((acc, cur) => {
    const taxonomy = courseBuilderConfig.catalog.catalog.included.find((taxonomyNode) => {
      return taxonomyNode.id === cur;
    });

    if (!taxonomy) {
      // This should only happen if there is no primary taxonomy for an isbn (which is a content bug)
      ELSLoggingService.error(fileName, 'Taxonomy not found: isbn primary taxonomy mismatch with catalog api response');
      return acc;
    }

    if (!taxonomy.relationships.children) {
      return acc;
    }

    const taxonomyChildrenNodes = taxonomy.relationships.children.data.map((child) => {
      return courseBuilderConfig.catalog.catalog.included.find((taxonomyNode) => {
        return taxonomyNode.id === child.id;
      });
    });

    return [
      ...acc,
      orderBy(taxonomyChildrenNodes, 'attributes.displayOrder')
    ];
  }, []);

  return stitchArrays(arrayOfTaxonomyNodeArray) as RecTaxonomyNodeDto[];
};

export const buildSubsections = (
  courseBuilderConfig: CourseBuilderConfig,
  sectionHeaders: SyllabusItemDto[],
  isOsmosisEnabled: boolean
): SyllabusItemDto[] => {
  const subSectionTaxonomyNodes = getSubSectionTaxonomyNodes(courseBuilderConfig);

  // Catalog external entities map
  const externalEntitiesMap = arrayToMapByCustomId(courseBuilderConfig.catalog.externalEntities, 'catalogItemId');

  const catalogItemConfigMap = getCatalogItemConfigMapClone(
    courseBuilderConfig.catalog,
    courseBuilderConfig.primaryTaxonomies,
    courseBuilderConfig.evolveProducts,
    isOsmosisEnabled,
    externalEntitiesMap
  );

  // Catalog included map
  const taxonomyRootNodesMap = arrayToMapByCustomId(courseBuilderConfig.catalog.catalog.included, 'id');

  if (subSectionTaxonomyNodes.length === 0) {

    const items = courseBuilderConfig.catalog.catalog.data
      .filter(filterIsTypeIncluded(courseBuilderConfig));

    const sortedTaxonomyIds = courseBuilderConfig.primaryTaxonomies
      ? getUniqueSortedPrimaryTaxonomies(courseBuilderConfig.primaryTaxonomies).map(x => x.taxonomy.data[0].id)
      : [];

    const itemSequenceMap = getSherpathLessonSequenceMap(courseBuilderConfig.moduleSequenceMap, null);
    const sortedItems = sortCatalogItemsClone({
      items,
      sortedTaxonomyIds,
      catalog: courseBuilderConfig.catalog,
      catalogItemConfigMap,
      dominantTaxon: null,
      taxonomyRootNodesMap,
      externalEntitiesMap,
      itemSequenceMap
    }).sectionedItems;

    return sortedItems.map((contentItem, idx, arr) => {
      const currentSectionIdx = getSectionIdxFromEvenlyDistributedSubSectionIdx(
        sectionHeaders.length,
        arr.length,
        idx
      );
      return buildContentItem(
        courseBuilderConfig,
        contentItem,
        courseBuilderConfig.evolveProducts,
        sectionHeaders[currentSectionIdx].id,
        idx,
        externalEntitiesMap
      );
    });
  }

  const result: SyllabusItemDto[] = [];
  const subSectionTaxonomyNodeIds = subSectionTaxonomyNodes.map(x => x.id);
  const nodeIds = getTaxonomiesWithFlattenedBranchChildren(courseBuilderConfig.catalog.catalog.included, subSectionTaxonomyNodeIds).map(mapToIds);
  // This map will return the parent taxonomy ([children]: [parent])
  const subSectionTaxonomyIdMapByChildrenId = subSectionTaxonomyNodes.reduce((acc, subSectionTaxonomyNode) => {
    const childrenTaxonomyIds = getTaxonomiesWithFlattenedBranchChildren(courseBuilderConfig.catalog.catalog.included, [subSectionTaxonomyNode.id]).map(mapToIds);
    childrenTaxonomyIds.forEach(childrenId => {
      acc[childrenId] = subSectionTaxonomyNode.id;
    });
    return acc;
  }, {});

  const catalogDataMapByNodeId = courseBuilderConfig.catalog.catalog.data.filter((contentItem) => {
    return filterIsTypeIncluded(courseBuilderConfig)(contentItem);
  }).reduce((acc, item) => {
    const foundTaxonomies = item.relationships.taxonomies.data.filter(x => nodeIds.includes(x.id));
    foundTaxonomies.forEach(foundTaxonomy => {
      if (foundTaxonomy) {
        if (!subSectionTaxonomyNodeIds.includes(foundTaxonomy.id)) {
          acc[subSectionTaxonomyIdMapByChildrenId[foundTaxonomy.id]] = [...(acc[subSectionTaxonomyIdMapByChildrenId[foundTaxonomy.id]] || []), item];
        } else {
          acc[foundTaxonomy.id] = [...(acc[foundTaxonomy.id] || []), item];
        }
      }
    });
    return acc;
  }, {});

  const originalAndOverrideSequenceMap = getSherpathLessonSequenceMapClone(courseBuilderConfig.moduleSequenceMap);
  for (let subSectionIdx = 0; subSectionIdx < subSectionTaxonomyNodes.length; subSectionIdx += 1) {
    const subSectionTaxonomyNode = subSectionTaxonomyNodes[subSectionIdx];

    const currentSectionIdx = getSectionIdxFromEvenlyDistributedSubSectionIdx(
      sectionHeaders.length,
      subSectionTaxonomyNodes.length,
      subSectionIdx
    );

    const subSectionSyllabusItem: SyllabusItemDto = {
      id: uuidv4(),
      parentId: sectionHeaders[currentSectionIdx].id,
      title: getEbookNodeTitle(subSectionTaxonomyNode),
      subtitle: null,
      type: ActiveSyllabusItemTypeDto.FOLDER,
      displayOrder: subSectionIdx,
      externalIdentifiers: [{
        type: ExternalIdTypeDto.TAXONOMY_NODE_ID,
        value: subSectionTaxonomyNode.id.toString()
      }],
      courseSectionIds: [parseInt(courseBuilderConfig.courseSectionId, 10)],
    };

    const subSectionContentItems = buildSubsectionContentItems(
      courseBuilderConfig,
      subSectionSyllabusItem,
      subSectionTaxonomyNode,
      catalogItemConfigMap,
      courseBuilderConfig.evolveProducts,
      taxonomyRootNodesMap,
      catalogDataMapByNodeId,
      originalAndOverrideSequenceMap,
      externalEntitiesMap
    );
    result.push(subSectionSyllabusItem, ...subSectionContentItems);

  }
  return result;
};

const areCourseBuilderConfigDatesValid = (courseBuilderConfig: CourseBuilderConfig): boolean => {
  if (!courseBuilderConfig.isIncludeDurationInTitle) {
    return true;
  }

  if (!courseBuilderConfig.startDate) {
    return false;
  }

  if (!courseBuilderConfig.endDate) {
    return false;
  }

  if (!isValidDate(courseBuilderConfig.startDate)) {
    return false;
  }

  if (!isValidDate(courseBuilderConfig.endDate)) {
    return false;
  }

  if (moment(courseBuilderConfig.endDate).isSameOrBefore(moment(courseBuilderConfig.startDate))) {
    return false;
  }

  return true;
};

export const isCourseBuilderConfigValid = (courseBuilderConfig: CourseBuilderConfig): boolean => {

  if (!courseBuilderConfig.courseName) {
    return false;
  }

  if (!courseBuilderConfig.courseName.trim() || courseBuilderConfig.courseName.trim().length > COURSE_NAME_MAX_LENGTH) {
    return false;
  }

  if (courseBuilderConfig.contentCreateMethod === contentCreateMethod.BLANK) {
    return !!courseBuilderConfig.firstFolderTitle;
  }

  // Everything after here is checking the custom course builder conditions

  if (!courseBuilderConfig.numberOfSections || courseBuilderConfig.numberOfSections <= 0 || courseBuilderConfig.numberOfSections > 999) {
    return false;
  }

  if (!isInteger(courseBuilderConfig.numberOfSections)) {
    return false;
  }

  return areCourseBuilderConfigDatesValid(courseBuilderConfig);

};

export const buildCourseSyllabusItems = (courseBuilderConfig: CourseBuilderConfig, isOsmosisEnabled: boolean): SyllabusItemDto[] => {

  if (courseBuilderConfig.contentCreateMethod === contentCreateMethod.BLANK) {
    return [{
      id: uuidv4(),
      parentId: null,
      title: courseBuilderConfig.firstFolderTitle,
      subtitle: null,
      type: ActiveSyllabusItemTypeDto.FOLDER,
      displayOrder: 0,
      externalIdentifiers: [],
      courseSectionIds: [parseInt(courseBuilderConfig.courseSectionId, 10)],
    }];
  }
  // Create week folders
  const sections = buildSections(courseBuilderConfig);
  const subSections = buildSubsections(courseBuilderConfig, sections, isOsmosisEnabled);
  return [...sections, ...subSections];
};

export const getTreeMap = (
  syllabusItems: SyllabusItemDto[],
  syllabusItem?: SyllabusItemDto,
  level = 1
): SyllabusTreeMapItem[] => {

  const items = syllabusItems.filter((item) => {
    if (!syllabusItem) {
      return !item.parentId;
    }

    return item.parentId === syllabusItem.id;
  });

  if (!items || !items.length) {
    return [];
  }

  return items.map((item) => {
    return {
      syllabusItem: item,
      level,
      children: getTreeMap(syllabusItems, item, level + 1)
    };
  });
};

// This is a clone of getTreeMap but it uses the parentMap to get children items
// instead of iterating through syllabusItems multiple times
// If no syllabusItem is provided, it will get all root level items, which are root folders
// Can remove the original function once all calls to getTreeMap are updated to use this new function
export const getTreeMapClone = (
  syllabusItems: SyllabusItemDto[],
  parentMap: Map<string, SyllabusItemDto[]>,
  syllabusItem?: SyllabusItemDto,
  level = 1
): SyllabusTreeMapItem[] => {

  const items = parentMap.get(syllabusItem?.id || 'root') || [];

  if (!items || !items.length) {
    return [];
  }

  return items.map((item) => {
    return {
      syllabusItem: item,
      level,
      children: getTreeMapClone(syllabusItems, parentMap, item, level + 1)
    };
  });
};

export const flattenTreeMap = (treeMapItems: SyllabusTreeMapItem[]): SyllabusTreeMapItem[] => {
  return treeMapItems.reduce((flattenedItems, item) => {
    flattenedItems.push(item);
    if (item.children && item.children.length) {
      flattenedItems.push(...flattenTreeMap(item.children));
    }
    return flattenedItems;
  }, []);
};

export const flattenTreeMapV2 = (treeMapItems: SyllabusTreeMapItem[]): SyllabusTreeMapItem[] => {
  const result: SyllabusTreeMapItem[] = [];
  const stack: SyllabusTreeMapItem[] = treeMapItems.slice().reverse();

  while (stack.length) {
    const current = stack.pop();
    result.push(current);
    if (current.children && current.children.length) {
      stack.push(...current.children.slice().reverse());
    }
  }

  return result;
};

export const getSortedSyllabusTreeMapItems = (syllabus: SyllabusItemDto[]): SyllabusTreeMapItem[] => {
  const flatSortedList = sortDefault(syllabus.filter((item) => !item.isDeleted));
  const treeMap = getTreeMap(flatSortedList);
  return flattenTreeMap(treeMap);
};

// This is a clone of getSortedSyllabusTreeMapItems but it uses the parentMap to get children items
// instead of iterating through the syllabus multiple times in getTreeMap
// The folders will be value to key of 'root' in the map because they do not have parentId
// Can remove the original function once all calls to getSortedSyllabusTreeMapItems are updated to use this new function
export const getSortedSyllabusTreeMapItemsClone = (syllabus: SyllabusItemDto[]): SyllabusTreeMapItem[] => {
  const flatSortedList = sortDefault(syllabus.filter((item) => !item.isDeleted));
  const parentMap = new Map<string, SyllabusItemDto[]>();
  flatSortedList.forEach(item => {
    const key = item.parentId || 'root';
    if (!parentMap.has(key)) {
      parentMap.set(key, []);
    }
    parentMap.get(key).push(item);
  });
  const treeMap = getTreeMapClone(flatSortedList, parentMap);
  return flattenTreeMapV2(treeMap);
};

export const getEndDate = (
  startDate: Date,
  sectionDurationDays: number,
  numberOfSections: number
): string => {
  if (isNaN(sectionDurationDays) || isNaN(numberOfSections)) {
    return null;
  }
  const courseLength = sectionDurationDays * numberOfSections;
  return moment(startDate).add(courseLength, 'days').format(DATE_PRIMARY_CHUNK);
};

export const getDashes = (num: number) => {
  let dashes = (<span />);
  times(num, () => {
    dashes = (<span>{dashes}&ndash;&nbsp;</span>);
  });
  return dashes;
};

export const getCourseBuilderTaxonomies = (
  primaryTaxonomies: PrimaryTaxonomy[],
): string[] => {

  // The primary taxonomies for the main product isbns on this course
  // Note that we can use the primary taxonomies for sub components as well so need
  // to be careful to filter by the main isbns

  if (!primaryTaxonomies || !primaryTaxonomies.length) {
    return [];
  }

  const ids = primaryTaxonomies.reduce((acc, cur) => {

    if (!cur.taxonomy || !cur.taxonomy.data) {
      ELSLoggingService.warn(fileName, 'Missing taxonomy data for isbn', cur.isbn);
      // This should never happen.  Added it for a broken unit test
      return acc;
    }

    if (cur.taxonomy.data.length !== 1) {
      ELSLoggingService.warn(fileName, 'More than 1 primary taxonomy found for ISBN', cur.isbn);
    }

    const taxonomiesForCourseBuilder = cur.taxonomy.data.filter((taxon) => {
      return taxon.attributes && taxon.attributes.priority === 1;
    });

    return [...acc, ...taxonomiesForCourseBuilder.map(mapToIds)];
  }, []);

  if (ids.length) {
    return uniq(ids);
  }

  ELSLoggingService.warn(fileName, 'No primary taxonomies found.');
  return null;
};

const evolveProductsNotRelevantToSherpathCourseCopy: EvolveProductTypeKey[] = [
  EvolveProductTypeKey.NURSING_CONCEPTS_IA,
  EvolveProductTypeKey.HESI_CASE_STUDY,
  EvolveProductTypeKey.HESI_PATIENT_REVIEW,
  EvolveProductTypeKey.HESI_PRACTICE_TEST,
  EvolveProductTypeKey.SIMULATION_SIM_CHART,
];

const byIsCourseCopyRelevant = (entitlement: ELSCourseSectionDto['entitlements'][0]) => {

  if (!entitlement || !entitlement.data) {
    return true;
  }

  let evolveProductDto = null as EvolveProductDto;
  try {
    evolveProductDto = JSON.parse(entitlement.data) as EvolveProductDto;
  } catch (e) {
    evolveProductDto = null;
  }
  if (evolveProductDto === null) {
    return true;
  }
  return !evolveProductsNotRelevantToSherpathCourseCopy.includes(evolveProductDto.productTypeKey);
};
export const buildOldCourseSameIsbnsAndClosestToCurrentDate = (courseSections: ELSCourseSectionDto[], currentCourseSection: ELSCourseSectionDto | CourseSectionDto) => {
  const courseEntitlementIsbns = currentCourseSection.entitlements
    .filter(byIsCourseCopyRelevant)
    .map(entitlement => entitlement.isbn);
  return uniqBy(courseSections.filter(courseSection => {
    if (courseSection.entitlements.length === 0 || courseSection.id === currentCourseSection.id) {
      return false;
    }
    return courseSection.entitlements
      .filter(byIsCourseCopyRelevant)
      .every(entitlement => includes(courseEntitlementIsbns, entitlement.isbn));
  }).sort((a, b) => {
    return b.createdAt - a.createdAt;
  }), 'id');
};

export const buildOldCourseName = (courseSection: ELSCourseSectionDto) => {
  return `${courseSection.courseName} (created on ${moment(courseSection.createdAt).format(DATE_PRIMARY)})`;
};

export const getTypeOptionsForCourseBuilder = (
  catalog: CourseBuilderConfig['catalog'],
  taxonomyIds: CourseBuilderConfig['taxonomyIds'],
  featureFlagsGrouped: FeatureFlagsGroupedDto[],
  courseSectionId: string,
  userRole: string
): ResourceFilterMap => {

  const unSupportedEvolveResourceTypes = getFeatureFlagGroups(featureFlagsGrouped, FEATURE_FLAG.IS_UNSUPPORTED_EVOLVE_RESOURCE_TYPE);
  const courseBuilderEvolveResourceTypes = getFeatureFlagGroups(featureFlagsGrouped, FEATURE_FLAG.IS_COURSE_BUILDER_EVOLVE_RESOURCE_TYPE);

  const subsectionTaxonomyNodes = getSubSectionTaxonomyNodes({ catalog, taxonomyIds });

  const catalogItems = catalog.catalog.data
    .filter((recContentItemDto) => {
      return isResourceIncludedInDefaultCoursePlan(recContentItemDto, catalog, unSupportedEvolveResourceTypes, courseBuilderEvolveResourceTypes);
    })
    .filter((recContentItemDto) => {

      if (!subsectionTaxonomyNodes || !subsectionTaxonomyNodes.length) {
        return true;
      }

      if (
        !recContentItemDto.relationships
        || !recContentItemDto.relationships.taxonomies
        || !recContentItemDto.relationships.taxonomies.data
      ) {
        return false;
      }

      return recContentItemDto.relationships.taxonomies.data.some((relationship) => {
        return subsectionTaxonomyNodes.some((node) => {
          return node.id === relationship.id;
        });
      });

    });

  return getTypeFilterOptionsForCatalog(
    catalog,
    userRole,
    featureFlagsGrouped,
    courseSectionId,
    catalogItems
  );
};

export const getSherpathClassicEbookReadingTitle = (contentItem: SherpathClassicContentItemDto) => {
  if (!contentItem || contentItem.type !== SherpathContentTypeDto.EBOOK) {
    return null;
  }

  const data: {
    chapters: {
      chapterNumber: string;
      chapterTitle: string;
      checked: boolean;
      pages: string[];
      vtwId: string[];
    }[];
    title: string;
  } = JSON.parse(contentItem.content.contentDetail.overrides);

  const pages = data.chapters.map((chapter) => {
    return `Chapter ${chapter.chapterNumber}, ${chapter.chapterTitle}: pp. ${chapter.pages.join(', ')}`;
  }).join('; ');
  return `${data.title} - ${pages}`;
};

export const getCourseCopyPreviewWarningContentItems = (courseCopyPreviewDto: CourseCopyPreviewDto): CourseWarningContentItem[] => {
  const { resources, syllabusItemsNotToBeCopied, retiredCatalogSyllabusItems } = courseCopyPreviewDto;
  let resourcesContentItems: CourseWarningContentItem[] = [];
  let syllabusItemsNotToBeCopiedContentItems: CourseWarningContentItem[] = [];
  let retiredCatalogSyllabusItemsContentItems: CourseWarningContentItem[] = [];

  if (!isEmpty(resources)) {
    resourcesContentItems = resources.reduce((acc, module) => {
      if (module.assessment) {
        return [
          ...acc,
          {
            type: SherpathContentType.NAQ,
            title: module.assessment.title
          }
        ];
      }

      if (module.contentScheduleDetail && module.contentScheduleDetail.length) {
        return module.contentScheduleDetail.reduce((_acc, contentItem) => {

          if (contentItem.type === SherpathContentTypeDto.EBOOK) {
            return _acc;
          }

          const data: { title: string } = JSON.parse(contentItem.content.data);
          return [
            ..._acc,
            {
              type: contentItem.type,
              title: data.title
            }
          ];
        }, acc);
      }

      return acc;
    }, []);
  }
  if (!isEmpty(syllabusItemsNotToBeCopied)) {
    syllabusItemsNotToBeCopiedContentItems = syllabusItemsNotToBeCopied.map(item => ({
      type: item.type,
      title: item.title
    }));
  }

  if (retiredCatalogSyllabusItems) {
    retiredCatalogSyllabusItemsContentItems = retiredCatalogSyllabusItems.map(item => ({
      type: item.type,
      title: item.title
    }));
  }

  return [
    ...resourcesContentItems,
    ...syllabusItemsNotToBeCopiedContentItems,
    ...retiredCatalogSyllabusItemsContentItems
  ];
};

export const hasUnableToCopyItems = (courseCopyPreviewDto: CourseCopyPreviewDto): boolean => {
  return !isEmpty(courseCopyPreviewDto) && (
    !isEmpty(courseCopyPreviewDto.resources)
    || !isEmpty(courseCopyPreviewDto.syllabusItemsNotToBeCopied)
    || !isEmpty(courseCopyPreviewDto.retiredCatalogSyllabusItems)
  );
};

export const getCourseCopyPreviewWarningTitlesByType = (
  courseCopyPreviewDto: CourseCopyPreviewDto
): Partial<Record<SherpathContentType | SherpathContentTypeDto | SyllabusItemTypeDto, string>> => {
  if (!hasUnableToCopyItems(courseCopyPreviewDto)) {
    return {};
  }

  const contentItems = getCourseCopyPreviewWarningContentItems(courseCopyPreviewDto);

  if (!contentItems || !contentItems.length) {
    return {};
  }

  return contentItems.reduce((acc, item) => {
    if (acc[item.type]) {
      return {
        ...acc,
        [item.type]: `${acc[item.type]}, ${item.title}`
      };
    }

    return {
      ...acc,
      [item.type]: item.title
    };
  }, {});
};

export const getCourseCopyPreviewWarningTypeCount = (
  courseCopyPreviewDto: CourseCopyPreviewDto
): Partial<Record<SherpathContentType | SherpathContentTypeDto | SyllabusItemTypeDto, number>> => {

  if (!hasUnableToCopyItems(courseCopyPreviewDto)) {
    return {};
  }

  const contentItems = getCourseCopyPreviewWarningContentItems(courseCopyPreviewDto);

  if (!contentItems || !contentItems.length) {
    return {};
  }

  return contentItems.reduce((acc, item) => {
    if (acc[item.type]) {
      return {
        ...acc,
        [item.type]: acc[item.type] + 1
      };
    }
    return {
      ...acc,
      [item.type]: 1
    };
  }, {});
};

export const getCourseCopyPreviewResourceWarningMsg = (
  typeCountMap: Partial<Record<SherpathContentType | SherpathContentTypeDto | SyllabusItemTypeDto, number>>
): string => {
  const messageArray = Object.keys(typeCountMap).reduce((acc, type) => {
    let typeDisplay = type;
    if (SherpathContentTypeMap[type]) {
      typeDisplay = typeCountMap[type] === 1 ? SherpathContentTypeMap[type].singular : SherpathContentTypeMap[type].plural;
    }
    return [...acc, `${typeCountMap[type]} ${typeDisplay}`];
  }, []);

  return joinListCommaSeparated(messageArray);
};

export const getAssignmentsFromCourseCopyPreviewDto = (courseCopyPreviewDto: CourseCopyPreviewDto): AssignmentDto[] => {

  if (!courseCopyPreviewDto || !courseCopyPreviewDto.syllabusItemResponse || !courseCopyPreviewDto.syllabusItemResponse.externalEntities) {
    return [];
  }

  return courseCopyPreviewDto.syllabusItemResponse.externalEntities
    .filter((ee) => {
      return ee.externalIdentifier && ee.externalIdentifier.type === ExternalIdTypeDto.ASSIGNMENT_ID;
    })
    .map<AssignmentDto>((ee) => {
      return ee.entity as AssignmentDto;
    });
};

export const getNewCourseClosedFolderIds = (newSyllabusItems: SyllabusItemDto[]) => {

  if (!newSyllabusItems || !newSyllabusItems.length) {
    return [];
  }

  const treeMap = getSortedSyllabusTreeMapItems(newSyllabusItems);

  const firstItem = treeMap.find((item) => {
    return item.syllabusItem.type !== ActiveSyllabusItemTypeDto.FOLDER;
  });

  if (!firstItem) {
    return [];
  }

  const openFolders = getAncestorLineFolderIds(newSyllabusItems, firstItem.syllabusItem.id);

  return newSyllabusItems.filter((syllabusItem) => {
    return syllabusItem.type === ActiveSyllabusItemTypeDto.FOLDER;
  }).map(mapToIds).filter((syllabusItemId) => {
    return !openFolders.includes(syllabusItemId);
  });
};

export const getSyllabusItemsForSave = (preview: SyllabusTreeMapItem[]): SyllabusItemDto[] => {
  if (!preview || !preview.length) {
    return [];
  }
  return preview.map((item) => {
    return truncateSyllabusItemTitles(item);
  });
};

export const removeMigratedCourses = (
  oldCourseDropDown: {
    name: string;
    value: string;
  }[],
  migratedCourses: ELSCourseMigrationSectionDto[],
  isEnabledMidEditionMigration: boolean
) => {

  if (!migratedCourses && migratedCourses.length === 0 && !isEnabledMidEditionMigration) {
    return oldCourseDropDown;
  }

  const migrationMap = new Map(migratedCourses.map((courseMigrationSection) => {
    return [courseMigrationSection.courseSectionId.toString(), courseMigrationSection.courseMigrationStatus];
  }));
  return oldCourseDropDown.filter((course) => {
    return migrationMap.get(course.value) !== migrationStatus.COMPLETED;
  });
};
