import { ELSAssignmentEditorGradeOptions } from '@els/els-component-shared-ts-react';
import { AssignmentType } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { ELSDropDownOption } from '../../components/els.components';
import {
  ExtendDatesValue,
  SortByValue
} from './bulk-edit-settings.models';
import {
  NavItemTextMap,
  NavItem
} from '../../components/header-nav/navigation.constants';

export const invalidDateErrorMessage = 'Please use MM-DD-YYYY format';
export const dueDateBeforeNowErrorMessage = 'Due date cannot be in the past';
export const availableDateNullErrorMessage = 'Must have an availability date to include a due date';
export const dueDateNullErrorMessage = 'Must have an availability date and a due date to include a grading type';
export const dueDateNullErrorMessageTwo = 'Must have an availability date and a due date to include grading type and grade points';
export const dueDateExtensionWarning = `In some Learning Management Systems grades can only be set from third party applications one time.
For assignments where the due date is in the past, grades that have been synced cannot be updated.
You may extend the due date and view updated grades and scores in the ${NavItemTextMap[NavItem.PERFORMANCE_AND_GRADES]} section of the application.`;
export const errorsInListMessage = 'Please correct the errors shown in red';
export const invalidPushDatesErrorMessage = 'Must be a whole number from 0 to 999';

export const gradingOptions: ELSDropDownOption[] = ELSAssignmentEditorGradeOptions.map(item => ({
  name: item.primaryText,
  value: item.value
}));

export enum PassFailOnlyAssignmentType {
  CHART = AssignmentType.CHART,
  MASTERY = AssignmentType.MASTERY,
  AUTHESS = AssignmentType.AUTHESS,
  SIMULATIONS = AssignmentType.SIMULATIONS,
  OSMOSIS_VIDEO = AssignmentType.OSMOSIS_VIDEO,
}

export enum ScoredOnlyAssignmentType {
  SHADOW_HEALTH = AssignmentType.SHADOW_HEALTH,
}

export enum UnSupportedGradePointsAssignmentType {
  CHART = AssignmentType.CHART,
  CASE_STUDY = AssignmentType.CASE_STUDY,
  MASTERY = AssignmentType.MASTERY,
  QUIZ_BY_QUESTION = AssignmentType.QUIZ_BY_QUESTION,
  STANDARD = AssignmentType.STANDARD,
  AUTHESS = AssignmentType.AUTHESS,
  PERSONAL_ASSESSMENT_BUILDER = AssignmentType.PERSONAL_ASSESSMENT_BUILDER
}

export const sortByOptions: ELSDropDownOption[] = [
  {
    name: 'Course Plan',
    value: SortByValue.COURSE_PLAN,
  }, {
    name: 'Resource Type (Ascending)',
    value: SortByValue.TYPE_ASC,
  }, {
    name: 'Resource Type (Descending)',
    value: SortByValue.TYPE_DESC,
  }, {
    name: 'Resource Name (Ascending)',
    value: SortByValue.TITLE_ASC,
  }, {
    name: 'Resource Name (Descending)',
    value: SortByValue.TITLE_DESC,
  }, {
    name: 'Due Date (Ascending)',
    value: SortByValue.DUE_DATE_ASC,
  }, {
    name: 'Due Date (Descending)',
    value: SortByValue.DUE_DATE_DESC,
  }, {
    name: 'Availability Date (Ascending)',
    value: SortByValue.AVAILABLE_DATE_ASC,
  }, {
    name: 'Availability Date (Descending)',
    value: SortByValue.AVAILABLE_DATE_DESC,
  }, {
    name: 'Grade Type (Ascending)',
    value: SortByValue.ASSIGNMENT_GRADE_TYPE_ASC,
  }, {
    name: 'Grade Type (Descending)',
    value: SortByValue.ASSIGNMENT_GRADE_TYPE_DESC,
  },
];

export enum BulkEditIcon {
  PENCIL = 'pencil',
  NO_EDITING = 'no-editing',
}

export const modalOverflowModifier = 'c-els-modal--no-overflow';
export const widthColumnItem = 'u-cw-max-width-8x';

export const extendDatesOptions: ELSDropDownOption[] = [
  {
    name: 'To a later date',
    value: ExtendDatesValue.TO_A_LATER_DATE
  }, {
    name: 'To an earlier date',
    value: ExtendDatesValue.TO_AN_EARLIER_DATE
  }
];

export enum BulkEditSettingsReturnAction {
  CANCEL = 'CANCEL',
  SAVE = 'SAVE'
}

export const AssignmentsWithUpdatableGradepoints = [
  AssignmentType.LESSONS,
  AssignmentType.ADAPTIVE_LESSON,
  AssignmentType.OSMOSIS_VIDEO,
  AssignmentType.SIMULATIONS,
  AssignmentType.SKILL,
  AssignmentType.SHADOW_HEALTH,
  AssignmentType.INTERACTIVE_REVIEW
];
