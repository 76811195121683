/* eslint-disable sonarjs/cognitive-complexity */
import moment from 'moment';
import {
  uniq,
  uniqBy,
  isArray,
  keys,
  difference,
  isNil,
} from 'lodash';
import {
  CatalogWithExternalEntitiesDto,
  CourseManagementSyllabusItemsDto,
} from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import { coursewareActionType } from './courseware.actions';
import { PrimaryTaxonomy } from '../../apis/rec-gateway/rec-gateway.models';
import { SyllabusItemDto } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.dtos';
import {
  getEbookStateStateOnChange
} from '../../components/ebook-filter/ebook-filter.utilities';
import { memoizedGetResourceTypeFilterForCoursePlan } from '../../pages/course-plan/syllabus.utilities';
import {
  coursewareInitialState,
  initialFilterState
} from './courseware.constants';
import {
  CoursewareStore,
  CourseBuilderStore,
  CourseBuilderField,
  ReduxPageWithCatalog,
  ReduxPageWithPrimaryTaxonomies,
  ReduxPageWithFilter,
  ReduxPage,
} from './courseware.models';
import {
  contentCreateMethod,
  courseCreateMethod,
} from '../../pages/course-builder/courseBuilder.constants';
import {
  AssessmentDto,
  AssignmentDto
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { EbookFilterChangeProps } from '../../components/ebook-filter/ebook-filter.models';
import { ActiveSyllabusItemTypeDto } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.constants';
import { getIsbnsForVantageCourse } from '../../utilities/app.utilities';
import { addFeatureFlagWithGroup } from '../redux.utilities';
import { SequenceMap } from '../../apis/ocs-api-service/ocs-api-service.dtos';
import { ClinicalSkillsFilterChangeProps } from '../../components/clinical-skills-filter/clinical-skills-filter.models';
import { getClinicalSkillsStateStateOnChange } from '../../components/clinical-skills-filter/clinical-skills-filter.utilities';

const getNextSyllabusItems = (
  state: CoursewareStore,
  payload: SyllabusItemDto[] | SyllabusItemDto
): SyllabusItemDto[] => {
  if (isNil(payload)) {
    return state.syllabusItems;
  }
  const newSyllabusItems = isArray(payload) ? payload : [payload];
  return uniqBy([...newSyllabusItems, ...state.syllabusItems], 'id');
};

// TODO: Merge this function with below getNextAssignments and make it more generic for array type property in redux
const getNextAssessments = (
  state: CoursewareStore,
  payload: AssessmentDto[] | AssessmentDto
): AssessmentDto[] => {
  if (isNil(payload)) {
    return state.assessments;
  }
  const newAssessments = isArray(payload) ? payload : [payload];
  return uniqBy([...newAssessments, ...state.assessments], 'id');
};

const getNextAssignments = (
  state: CoursewareStore,
  payload: AssignmentDto[] | AssignmentDto
): AssignmentDto[] => {
  if (isNil(payload)) {
    return state.assignments;
  }
  const newAssignments = isArray(payload) ? payload : [payload];
  return uniqBy([...newAssignments, ...state.assignments], 'id');
};

const getEndDate = (state: CourseBuilderStore): string => {
  if (state[CourseBuilderField.COURSE_CREATE_METHOD] === courseCreateMethod.NEW
    && state[CourseBuilderField.CONTENT_CREATE_METHOD] === contentCreateMethod.TEMPLATE
    && state[CourseBuilderField.NUMBER_OF_SECTIONS] > 0
    && state[CourseBuilderField.START_DATE]) {
    const endDate = moment(state[CourseBuilderField.START_DATE])
      .add(state[CourseBuilderField.NUMBER_OF_SECTIONS], 'weeks')
      .add(-1, 'days')
      .toISOString();

    if (!moment(endDate).isSame(moment(state[CourseBuilderField.END_DATE]))) {
      return endDate;
    }
  }
  return state[CourseBuilderField.END_DATE];
};

const getNewCourseBuilderState = (state, payload: Partial<CourseBuilderStore>): CourseBuilderStore => {
  const newState: CourseBuilderStore = {
    ...state.courseBuilderState,
    ...payload
  };
  newState[CourseBuilderField.END_DATE] = getEndDate(newState);
  return newState;
};

const getNextResourceTypeFilterState = (state: CoursewareStore, payload: SyllabusItemDto[] | SyllabusItemDto) => {
  const nextSyllabusItems = getNextSyllabusItems(state, payload);
  // TODO: Need to fix this
  const filterResourceTypeAfterDelete = keys(memoizedGetResourceTypeFilterForCoursePlan(
    nextSyllabusItems,
    null,
    null
  ));
  const excessResourceTypeFilterState = difference(state[ReduxPage.COURSE_PLAN_PAGE].filterState.resourceTypeFilter, filterResourceTypeAfterDelete);
  if (excessResourceTypeFilterState.length !== 0) {
    return state[ReduxPage.COURSE_PLAN_PAGE].filterState.resourceTypeFilter.filter(type => !excessResourceTypeFilterState.includes(type));
  }
  return state[ReduxPage.COURSE_PLAN_PAGE].filterState.resourceTypeFilter;
};

export const coursewareReducer = (state = coursewareInitialState, action): CoursewareStore => {
  // eslint-disable-next-line sonarjs/max-switch-cases
  switch (action.type) {
    case coursewareActionType.REQUEST_START:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount + 1,
      };
    case coursewareActionType.REQUEST_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
      };
    case coursewareActionType.REQUEST_ERROR:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        errors: [action.payload, ...state.errors]
      };
    case coursewareActionType.RESTORE_STATE:
      return {
        ...coursewareInitialState,
        ...action.payload
      };
    case coursewareActionType.SET_ISBNS:
      return {
        ...state,
        isbns: action.payload
      };
    case coursewareActionType.SET_USER:
      return {
        ...state,
        courseSectionId: action.payload.courseSectionId.toString(),
        roleId: action.payload.roleId,
        userId: action.payload.userId,
      };
    case coursewareActionType.SET_REGISTERED_TOKEN:
      return {
        ...state,
        registeredToken: action.payload
      };
    case coursewareActionType.FETCH_COURSE_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        currentCourse: action.payload,
        vantageIsbns: getIsbnsForVantageCourse(state.isbns, action.payload)
      };
    case coursewareActionType.POST_COURSE_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        currentCourse: state.currentCourse ? {
          ...state.currentCourse,
          courseName: action.payload.courseName,
          locked: action.payload.locked,
        } : action.payload
      };
    case coursewareActionType.FETCH_USERS_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        users: action.payload
      };
    case coursewareActionType.FETCH_USER_HISTORY_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        userHistory: uniqBy([...action.payload.response, ...state.userHistory], 'id'),
        userHistoryStateCompletedRequests: uniq([...state.userHistoryStateCompletedRequests, action.payload.stateKey])
      };
    case coursewareActionType.DELETE_USER_HISTORY_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        userHistory: coursewareInitialState.userHistory,
        userHistoryStateCompletedRequests: coursewareInitialState.userHistoryStateCompletedRequests,
        isCourseOwner: coursewareInitialState.isCourseOwner,
        skillSubmissionRecords: coursewareInitialState.skillSubmissionRecords,
      };
    case coursewareActionType.FETCH_GROUP_ACTIVITY_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        groupActivity: action.payload
      };
    case coursewareActionType.POST_USER_HISTORY_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        userHistory: uniqBy([action.payload, ...state.userHistory], 'id')
      };
    case coursewareActionType.SET_IS_COURSE_OWNER_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        isCourseOwner: action.payload.stateInfo,
        userCourseOwnerRecords: uniqBy([action.payload, ...state.userCourseOwnerRecords], 'id')
      };
    case coursewareActionType.FETCH_USER_COURSE_OWNER_RECORDS_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        userCourseOwnerRecords: action.payload
      };
    case coursewareActionType.SET_IS_USER_COURSE_OWNER_RECORDS_FETCHED:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        isUserCourseOwnerRecordsFetched: action.payload
      };
    case coursewareActionType.FETCH_LINK_DATA_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        linkData: action.payload
      };
    case coursewareActionType.SET_MESSAGES:
      return {
        ...state,
        messages: action.payload
      };
    case coursewareActionType.FETCH_GROUPED_FEATURE_FLAGS_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        featureFlagsGrouped: action.payload
      };
    case coursewareActionType.FETCH_COURSE_SETTINGS_FEATURE_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        courseSettingsFlags: {
          ...state.courseSettingsFlags,
          ...action.payload
        }
      };
    case coursewareActionType.ADD_FEATURE_FLAG:
      return {
        ...state,
        featureFlagsGrouped: addFeatureFlagWithGroup(action.payload, state.featureFlagsGrouped)
      };
    case coursewareActionType.FETCH_CATALOG_SUCCESS: {
      const {
        data,
        reduxPage,
        moduleSequenceMap
      } = action.payload as {
        data: CatalogWithExternalEntitiesDto;
        reduxPage: ReduxPageWithCatalog;
        moduleSequenceMap: SequenceMap;
      };
      let updatedState: Partial<CoursewareStore> = {};
      if (reduxPage) {
        updatedState = {
          [reduxPage]: {
            ...state[reduxPage],
            catalog: data,
            moduleSequenceMap
          },
        };
      } else {
        updatedState.catalog = data;
        updatedState.moduleSequenceMap = moduleSequenceMap;
      }
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        ...updatedState,
      };
    }
    case coursewareActionType.FETCH_PRIMARY_TAXONOMY_SUCCESS: {
      const { data, reduxPage } = action.payload as { data: PrimaryTaxonomy[]; reduxPage: ReduxPageWithPrimaryTaxonomies };
      const primaryTaxonomies = uniqBy([...state.primaryTaxonomies, ...data], (item) => {
        return item.isbn;
      });
      let updatedState: Partial<CoursewareStore> = {};
      if (reduxPage) {
        updatedState = {
          [reduxPage]: {
            ...state[reduxPage],
            primaryTaxonomies,
          },
        };
      } else {
        updatedState = {
          primaryTaxonomies
        };
      }
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        ...updatedState,
      };
    }
    case coursewareActionType.SET_CHECKED_SYLLABUS_ITEMS:
      return {
        ...state,
        checkedSyllabusItemIds: action.payload,
      };
    case coursewareActionType.SET_IS_BATCH_EDIT_MODE:
      return {
        ...state,
        isBatchEditModeEnabled: action.payload,
      };
    case coursewareActionType.SET_IS_DRAG_DROP_MODE:
      return {
        ...state,
        isDragDropModeEnabled: action.payload,
      };
    case coursewareActionType.DELETE_SYLLABUS_ITEMS_SUCCESS: {
      const updateState = {
        ...state[ReduxPage.COURSE_PLAN_PAGE].filterState,
        resourceTypeFilter: getNextResourceTypeFilterState(state, action.payload)
      };
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        syllabusItems: getNextSyllabusItems(state, action.payload),
        ...updateState
      };
    }
    case coursewareActionType.POST_SYLLABUS_ITEMS_SUCCESS:
    case coursewareActionType.PUT_SYLLABUS_ITEMS_BATCH_SUCCESS:
    case coursewareActionType.POST_SYLLABUS_ITEMS_BATCH_SUCCESS:
    case coursewareActionType.PUT_SYLLABUS_ITEM_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        syllabusItems: getNextSyllabusItems(state, action.payload)
      };
    case coursewareActionType.FETCH_SYLLABUS_ITEMS_SUCCESS: {
      const { syllabusItems, externalEntities } = action.payload as CourseManagementSyllabusItemsDto;
      return {
        ...state,
        externalEntities,
        pendingRequestCount: state.pendingRequestCount - 1,
        syllabusItems,
      };
    }
    case coursewareActionType.FETCH_ASSIGNMENTS_SUCCESS: {
      const { assignments } = action.payload;
      return {
        ...state,
        assignments,
        pendingRequestCount: state.pendingRequestCount - 1,
      };
    }
    case coursewareActionType.UPDATE_COURSE_BUILDER_STATE:
      return {
        ...state,
        courseBuilderState: getNewCourseBuilderState(state, action.payload)
      };
    case coursewareActionType.POST_SYLLABUS_ASSIGNMENT_SUCCESS:
    case coursewareActionType.PUT_SYLLABUS_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        assignments: getNextAssignments(state, action.payload.assignment),
        syllabusItems: getNextSyllabusItems(state, action.payload.syllabusItemDTO),
      };
    case coursewareActionType.PUT_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        assignments: state.assignments.map((assignment) => {
          if (action.payload[assignment.id]) {
            return action.payload[assignment.id];
          }
          return assignment;
        })
      };
    case coursewareActionType.FETCH_ASSESSMENT_SUBMISSIONS_SUCCESS:
      return {
        ...state,
        pendingRequestCount: action.payload.isTrackRequestCount ? state.pendingRequestCount - 1 : state.pendingRequestCount,
        assessmentSubmissionsMap: {
          ...state.assessmentSubmissionsMap,
          [action.payload.assignmentId]: action.payload.assessmentSubmissions
        }
      };
    case coursewareActionType.FETCH_ALL_ASSESSMENT_SUBMISSIONS_SUCCESS:
      return {
        ...state,
        pendingRequestCount: action.payload.isTrackRequestCount ? state.pendingRequestCount - 1 : state.pendingRequestCount,
        assessmentSubmissionsMap: {
          ...state.assessmentSubmissionsMap,
          ...action.payload.assessmentSubmissionsMap
        }
      };
    case coursewareActionType.FETCH_ALL_EVOLVE_INSTRUCTOR_RESOURCE_DATA_SUCCESS:
      return {
        ...state,
        pendingRequestCount: action.payload.isTrackRequestCount ? state.pendingRequestCount - 1 : state.pendingRequestCount,
        evolveInstructorResourceDataMap: {
          ...state.evolveInstructorResourceDataMap,
          ...action.payload.parsedDataMap
        }
      };
    case coursewareActionType.FETCH_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        simulationAssignment: action.payload,
        pendingRequestCount: state.pendingRequestCount - 1,
      };
    case coursewareActionType.FETCH_EOLS_USER_SUCCESS:
      return {
        ...state,
        eolsUser: action.payload,
        pendingRequestCount: state.pendingRequestCount - 1,
      };
    case coursewareActionType.FETCH_EVOLVE_USER_SUCCESS:
      return {
        ...state,
        evolveUser: action.payload,
        pendingRequestCount: state.pendingRequestCount - 1,
      };
    case coursewareActionType.FETCH_CROSSWALK_USERS_SUCCESS:
      return {
        ...state,
        crosswalkUsers: action.payload,
        pendingRequestCount: state.pendingRequestCount - 1,
      };
    case coursewareActionType.HANDLE_EBOOK_FILTER_CHANGE: {
      const { props, reduxPage } = action.payload as { props: EbookFilterChangeProps; reduxPage: ReduxPageWithFilter };
      const updatedState: Partial<CoursewareStore> = {
        [reduxPage]: {
          ...state[reduxPage],
          filterState: {
            ...state[reduxPage].filterState,
            ebookFilterState: getEbookStateStateOnChange(props, state[reduxPage].filterState.ebookFilterState)
          }
        },
      };
      return {
        ...state,
        ...updatedState,
      };
    }
    case coursewareActionType.HANDLE_CLINICAL_SKILLS_FILTER_CHANGE: {
      const { props, reduxPage } = action.payload as { props: ClinicalSkillsFilterChangeProps; reduxPage: ReduxPageWithFilter };
      const updatedState: Partial<CoursewareStore> = {
        [reduxPage]: {
          ...state[reduxPage],
          filterState: {
            ...state[reduxPage].filterState,
            clinicalSkillsFilterState: getClinicalSkillsStateStateOnChange(props, state[reduxPage].filterState.clinicalSkillsFilterState)
          }
        },
      };
      return {
        ...state,
        ...updatedState,
      };
    }
    case coursewareActionType.SET_EBOOK_FILTER: {
      const updateState = {
        ...state[ReduxPage.CATALOG_PAGE].filterState,
        ebookFilterState: action.payload
      };
      return {
        ...state,
        ...updateState
      };
    }
    case coursewareActionType.HANDLE_PRODUCT_FILTER_CHANGE: {
      const { product, reduxPage } = action.payload as { product: string; reduxPage: ReduxPageWithFilter };
      const updatedState: Partial<CoursewareStore> = {
        [reduxPage]: {
          ...state[reduxPage],
          filterState: {
            ...state[reduxPage].filterState,
            selectedProduct: product
          },
        },
      };
      return {
        ...state,
        ...updatedState,
      };
    }
    case coursewareActionType.HANDLE_RESOURCE_STATUS_FILTER_CHANGE: {
      const { props, reduxPage } = action.payload as { props: Array<string>; reduxPage: ReduxPageWithFilter };
      const updatedState: Partial<CoursewareStore> = {
        [reduxPage]: {
          ...state[reduxPage],
          filterState: {
            ...state[reduxPage].filterState,
            resourceStatusFilter: props
          },
        },
      };
      return {
        ...state,
        ...updatedState,
      };
    }
    case coursewareActionType.HANDLE_RESOURCE_TYPE_FILTER_CHANGE: {
      const { props, reduxPage } = action.payload as { props: Array<ActiveSyllabusItemTypeDto>; reduxPage: ReduxPageWithFilter };
      const updatedState: Partial<CoursewareStore> = {
        [reduxPage]: {
          ...state[reduxPage],
          filterState: {
            ...state[reduxPage].filterState,
            resourceTypeFilter: props
          }
        },
      };
      return {
        ...state,
        ...updatedState,
      };
    }
    case coursewareActionType.HANDLE_RESOURCE_GRADING_FILTER_CHANGE: {
      const { props, reduxPage } = action.payload as { props: Array<string>; reduxPage: ReduxPageWithFilter };
      const updatedState: Partial<CoursewareStore> = {
        [reduxPage]: {
          ...state[reduxPage],
          filterState: {
            ...state[reduxPage].filterState,
            resourceGradingFilter: props
          }
        },
      };
      return {
        ...state,
        ...updatedState,
      };
    }
    case coursewareActionType.SET_ASSESSMENT_START_TIME_MAP:
      return {
        ...state,
        assessmentStartTimeMap: {
          ...state.assessmentStartTimeMap,
          [action.payload.assessmentId]: action.payload.startTime,
        },
      };
    case coursewareActionType.SET_APP_LINK_COOKIES:
      return {
        ...state,
        appLinkCookies: {
          ...action.payload
        },
      };
    case coursewareActionType.UPDATE_COLLAPSED_FOLDER_IDS:
      return {
        ...state,
        collapsedFolderIds: action.payload,
      };
    case coursewareActionType.FETCH_SKILL_STATIC_DATA:
      return {
        ...state,
        skillStaticData: action.payload,
        pendingRequestCount: state.pendingRequestCount - 1,
      };
    case coursewareActionType.FETCH_SKILL_SUBMISSION_RECORD:
      return {
        ...state,
        skillSubmissionRecords: action.payload,
        pendingRequestCount: state.pendingRequestCount - 1,
      };
    case coursewareActionType.RESET_STORE:
      return {
        ...coursewareInitialState,
        messages: state.messages
      };
    case coursewareActionType.FETCH_COURSE_COPY_PREVIEW_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        courseBuilderState: {
          ...state.courseBuilderState,
          ...action.payload
        }
      };
    case coursewareActionType.SET_HESI_FOCUS_CHAPTER_FILTER: {
      const { props, reduxPage } = action.payload as { props: Array<string>; reduxPage: ReduxPageWithFilter };
      const updatedState: Partial<CoursewareStore> = {
        [reduxPage]: {
          ...state[reduxPage],
          filterState: {
            ...state[reduxPage].filterState,
            isHesiFocusChapterFilterEnabled: props
          }
        },
      };
      return {
        ...state,
        ...updatedState
      };
    }
    case coursewareActionType.SET_BATCH_EDIT_SELECTED_SYLLABUS_ITEMS:
      return {
        ...state,
        batchEditSelectedSyllabusItems: action.payload,
      };
    case coursewareActionType.SET_BATCH_EDIT_UPDATED_SYLLABUS_ITEMS:
      return {
        ...state,
        batchEditUpdatedSyllabusItems: action.payload,
      };
    case coursewareActionType.FETCH_USER_ENGAGEMENT_REPORT_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        userEngagementReport: action.payload,
      };
    case coursewareActionType.CLEAR_ALL_FILTERS: {
      const updatedState: Partial<CoursewareStore> = {
        [action.payload]: {
          ...state[action.payload],
          filterState: initialFilterState
        }
      };
      return {
        ...state,
        ...updatedState
      };
    }
    case coursewareActionType.FETCH_OSMOSIS_TOKEN_SUCCESS:
      return {
        ...state,
        osmosisTokenDto: action.payload,
        pendingRequestCount: state.pendingRequestCount - 1,
      };
    case coursewareActionType.SET_STORE_PROPS:
      return {
        ...state,
        ...action.payload
      };
    case coursewareActionType.FETCH_USER_RECENT_ASSESSMENT_BY_ASSIGNMENT_ID:
    case coursewareActionType.POST_CREATE_ASSESSMENT_FROM_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        assessments: getNextAssessments(state, action.payload),
        pendingRequestCount: state.pendingRequestCount - 1,
      };
    case coursewareActionType.SET_ENABLE_DEEP_LINK:
      return {
        ...state,
        enableDeepLink: action.payload
      };
    case coursewareActionType.SET_HAS_RUN_AUTHESS_HEALTH_CHECK:
      return {
        ...state,
        hasRunAuthessHealthCheck: action.payload
      };
    case coursewareActionType.FETCH_MIGRATED_ENTITLEMENTS_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        migratedEntitlements: action.payload
      };
    case coursewareActionType.FETCH_MISSING_MAPPING_RESOURCES_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        missingMappingResources: action.payload
      };
    case coursewareActionType.SET_SYLLABUS_FOLDER_INFO:
      return {
        ...state,
        syllabusFolderInfo: action.payload
      };
    case coursewareActionType.FETCH_AB_TEST_FLAVOR_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        abTestFlavors: [
          ...state.abTestFlavors,
          action.payload
        ]
      };
    case coursewareActionType.RESET_STATE_ON_LAUNCH:
      return {
        ...coursewareInitialState,
        // Window.sessionStorage follows the course switcher new tab so need to clear data
        // These state props are set in app component or app link component
        // before the reset so want to preserve them
        appLinkCookies: state.appLinkCookies,
        registeredToken: state.registeredToken,
        isbns: state.isbns,
        courseSectionId: state.courseSectionId,
        roleId: state.roleId,
        userId: state.userId,
        messages: state.messages,
      };
    case coursewareActionType.SET_PROGRESS_INDICATOR_VALUES:
      return {
        ...state,
        progressIndicatorValues: {
          completed: action.payload.completed,
          total: action.payload.total,
        }
      };
    case coursewareActionType.INCREMENT_PROGRESS_INDICATOR:
      return {
        ...state,
        progressIndicatorValues: {
          ...state.progressIndicatorValues,
          completed: state.progressIndicatorValues.completed + action.payload
        }
      };
    case coursewareActionType.SET_IS_APP_LINK_ASSIGNMENT_CREATE_FLOW:
      return {
        ...state,
        isAppLinkAssignmentCreateFlow: action.payload
      };
    case coursewareActionType.SET_APP_LINK_ASSIGNMENT_STUDENTS:
      return {
        ...state,
        appLinkAssignmentStudents: action.payload
      };
    case coursewareActionType.UPDATE_COLLAPSED_FILTER_TITLES:
      return {
        ...state,
        collapsedFilterTitles: action.payload,
      };
    case coursewareActionType.FETCH_CHAT_EEO_ISBNS_SUCCESS:
      return {
        ...state,
        chatSupportedEeoIsbns: action.payload,
        pendingRequestCount: state.pendingRequestCount - 1,
      };
    default:
      return state;
  }
};
