import { CatalogWithExternalEntitiesDto } from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import { ALL_OPTION_VALUE } from '../../pages/catalog/catalog.constants';
import {
  contentCreateMethod,
  courseCreateMethod,
  sectionTitleMethod,
} from '../../pages/course-builder/courseBuilder.constants';
import {
  CourseBuilderField,
  CoursewareStore,
  ReduxPage,
} from './courseware.models';

const initialCatalog = {
  catalog: {
    catalog: {
      data: [],
      included: []
    },
    externalEntities: [],
  } as CatalogWithExternalEntitiesDto,
};

export const initialFilterState = {
  ebookFilterState: {},
  clinicalSkillsFilterState: {},
  selectedProduct: ALL_OPTION_VALUE,
  resourceStatusFilter: [],
  resourceTypeFilter: [],
  resourceGradingFilter: [],
  isHesiFocusChapterFilterEnabled: false
};

export const coursewareInitialState: CoursewareStore = {
  userEngagementReport: null,
  userHistory: [],
  userHistoryStateCompletedRequests: [],
  courseSectionId: null,
  currentCourse: null,
  errors: [],
  evolveProducts: null,
  evolveUser: null,
  featureFlagsGrouped: null,
  courseSettingsFlags: null,
  isbns: null,
  vantageIsbns: null,
  isCourseOwner: false,
  linkData: null,
  messages: {},
  pendingRequestCount: 0,
  roleId: '',
  userCourseOwnerRecords: [],
  isUserCourseOwnerRecordsFetched: false,
  userId: null,
  users: [],
  eolsUser: null,
  catalog: initialCatalog.catalog,
  primaryTaxonomies: [],
  taxonomies: [],
  assignments: [],
  assessmentSubmissionsMap: null,
  evolveInstructorResourceDataMap: null,
  checkedSyllabusItemIds: [],
  isAppLinkAssignmentCreateFlow: false,
  appLinkAssignmentStudents: [],
  syllabusFolderInfo: {
    destinationId: null,
    locationValue: null
  },
  courseBuilderState: {
    isNewCourse: false,
    [CourseBuilderField.CONTENT_CREATE_METHOD]: contentCreateMethod.TEMPLATE,
    [CourseBuilderField.COURSE_CREATE_METHOD]: courseCreateMethod.NEW,
    [CourseBuilderField.COURSE_NAME]: '',
    [CourseBuilderField.CUSTOM_SECTION_TITLE]: 'Folder',
    [CourseBuilderField.END_DATE]: null,
    [CourseBuilderField.FIRST_FOLDER_TITLE]: 'Week 1',
    [CourseBuilderField.IS_COURSE_LOCKED]: false,
    [CourseBuilderField.IS_INCLUDE_DURATION_IN_TITLE]: true,
    [CourseBuilderField.NUMBER_OF_SECTIONS]: 16,
    [CourseBuilderField.SECTION_TITLE_METHOD]: sectionTitleMethod.WEEK,
    [CourseBuilderField.SHOW_CONTENT]: true,
    [CourseBuilderField.START_DATE]: null,
    [CourseBuilderField.TAXONOMY_IDS]: null,
    [CourseBuilderField.SELECT_EXISTING_COURSE]: null,
    [CourseBuilderField.COPY_COURSE_START_DATE]: null,
    selectedTypes: [],
    courseCopyPreviewDto: null,
    isDeepLinkCourseCopy: false,
  },
  externalEntities: [],
  isBatchEditModeEnabled: false,
  isDragDropModeEnabled: true,
  syllabusItems: [],
  simulationAssignment: null,
  crosswalkUsers: [],
  assessmentStartTimeMap: {},
  registeredToken: null,
  appLinkCookies: {
    token: null,
    linkId: null
  },
  collapsedFolderIds: [],
  skillStaticData: null,
  skillSubmissionRecords: [],
  [ReduxPage.CATALOG_PAGE]: {
    primaryTaxonomies: [],
    catalog: initialCatalog.catalog,
    filterState: initialFilterState
  },
  [ReduxPage.SKILL_EDITOR_PAGE]: initialCatalog,
  [ReduxPage.SIMULATION_EDITOR_PAGE]: initialCatalog,
  [ReduxPage.SHERPATH_LESSON_EDITOR_PAGE]: initialCatalog,
  [ReduxPage.EVOLVE_RESOURCES_EDITOR_PAGE]: initialCatalog,
  [ReduxPage.SHADOW_HEATH_EDITOR_PAGE]: initialCatalog,
  [ReduxPage.SHERPATH_POWERPOINT_EDITOR_PAGE]: initialCatalog,
  [ReduxPage.SHERPATH_GROUP_ACTIVITY_EDITOR_PAGE]: initialCatalog,
  [ReduxPage.SHERPATH_CASE_STUDY_EDITOR_PAGE]: initialCatalog,
  [ReduxPage.OSMOSIS_VIDEO_EDITOR_PAGE]: initialCatalog,
  [ReduxPage.OSMOSIS_VIDEO_PLAYER_PAGE]: initialCatalog,
  [ReduxPage.EBOOK_EDITOR_PAGE]: {
    primaryTaxonomies: []
  },
  [ReduxPage.COURSE_PLAN_PAGE]: {
    filterState: initialFilterState
  },
  batchEditSelectedSyllabusItems: [],
  batchEditUpdatedSyllabusItems: [],
  moduleSequenceMap: null,
  assessments: [],
  enableDeepLink: false,
  groupActivity: null,
  hasRunAuthessHealthCheck: null,
  osmosisTokenDto: null,
  migratedEntitlements: null,
  missingMappingResources: null,
  abTestFlavors: [],
  progressIndicatorValues: { completed: 0, total: 0 },
  isShowShadowHealthOrientationBanner: true,
  collapsedFilterTitles: [],
  chatSupportedEeoIsbns: null
};

export const UNKNOWN = 'UNKNOWN';
export const EAQ_SELF_STUDY = 'EAQ_SELF_STUDY';
